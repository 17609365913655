import { useEffect, useState } from "react";
import { sleep } from "utils/utils";

import styles from "./index.module.scss";

export interface BrandImageProps {
  brand_image: string;
  brand_images: string[];
}

const BrandImage = ({ brand_image, brand_images }: BrandImageProps) => {
  const [brandImage, setBrandImage] = useState(brand_image);
  useEffect(() => {
    brand_images?.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, [brand_images]);

  useEffect(() => {
    if (brand_images) {
      (async () => {
        while (true) {
          const random = Math.floor(Math.random() * brand_images.length);
          setBrandImage(brand_images[random]);
          await sleep(5000);
        }
      })();
    }
  }, [brand_images]);

  return (
    <div className={styles["image-holder"]}>
      <div className={styles.image}>
        <img src={brandImage} alt="ss" />
      </div>
      <div className={styles["image-container"]}>
        <div className=""></div>
      </div>
    </div>
  );
};

export default BrandImage;
